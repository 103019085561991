import { apiPost, apiGet } from '../utils/http';

// 上传图片
export async function uploadPhoto(data = {}, options) {
    const api = '/comment/uploadPhoto';
    return await apiPost(api, data, options);
}

// 发表评论
export async function releaseComment(data = {}, options) {
    const api = '/comment/releaseComment';
    return await apiPost(api, data, options);
}

export async function checkComments(data = {}, options) {
    const api = '/comment/detailList';
    return await apiGet(api, data, options);
}

export async function getCityList() {
    const api = '/comment/cityList';
    return await apiGet(api);
}

export async function getCommentList(data = {}, options) {
    const api = '/comment/list';
    return await apiGet(api, data, options);
}
